import {
  defineNuxtRouteMiddleware,
  // navigateTo,
} from 'nuxt/app';
import { isIosOrAndroid } from '@helpers/mobile';
import { useAuthStore } from '@stores/useAuthStore';

export default defineNuxtRouteMiddleware(async () => {
  const { user } = toRefs(useAuthStore());
  
  if (!isIosOrAndroid() && !user.value?.bypass_checks) {
    return navigateTo({ path: '/' });
  }
});
